@import '../../styles/colors';

.front-page {

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;

    a {
        display: inline-block;
        padding: 150px;
        margin: 50px 0;

        background-color: $brand-yellow;

        text-decoration: none;
        text-transform: uppercase;
        font-size: 50px;
        text-align: center;
    }
    a:hover {
        animation: ooze .5s cubic-bezier(0, 2, .5, .5);
        transform: scale(1.05);
        background-color: $brand-brighter-yellow;
        color: complement($brand-brighter-yellow);
    }
}

@keyframes ooze {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(1.05);
    }
}