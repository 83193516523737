@import '../../styles/colors';

$overlay-opacity: .3;
$overlay-color: darken($brand-green, 33%);
$modal-background-color: $brand-dark-gray;
$modal-color: $brand-white;

.modal-overlay {
    position: fixed;
    left:0;
    right:0;
    top: 0;
    bottom:0;
    background-color: $overlay-color;
    opacity: $overlay-opacity;
    z-index: 10;
    animation: fadein-modal-overlay 150ms ease-out;
    transition: opacity 0.2s ease-out;

    &.fade-out {
        opacity: 0;
    }
}

.modal-content {

    > .modal-close-button {
        position: absolute;
        top: 1.3rem;
        right: 1.3rem;
        padding: 1rem 1.5rem;
        background-color: transparent;
        border: none;
        cursor: pointer;
        color: $button-background-color;
        font-size: 3rem;

        &:hover {
        }
    }
    input[type=button] {
        color: $modal-background-color;
    }

    position: absolute;
    box-sizing: border-box;
    top: 20%;
    left: 50%;
    max-width: 830px;
    transform: translateX(-50%);
    width: 90%;
    color: $modal-color;
    opacity: 1;
    z-index: 11;
    padding: 8rem;
    background-color: $modal-background-color;
    font-size: 1.06125em;
    text-align: center;
    animation: emerge 250ms;
    margin-bottom: 20px;
    transition: opacity 0.2s ease-out;

    &.fade-out {
        opacity: 0;
    }
}

@keyframes fadein-modal-overlay {
    0% {
        opacity: 0;
    }
    100% {
        opacity: $overlay-opacity;
    }
}

@keyframes emerge {
     0% {
         transform: translateX(-50%) translateY(0) rotateY(90deg);
         opacity: 0;
     }
     100% {
         transform: translateX(-50%) translateY(0);
         opacity: 1;
     }
}
