
.react-root {
    height: 200px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    > .page-header {
        padding: 20px;
        font-size: 30px;
    }

    a {
        padding: 10px;
    }

    .children {
        font-size: 16px;
        div {
            padding: 10px 0px;
        }
    }
}
