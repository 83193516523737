@import '../../styles/colors';

.match {
    margin: 10px 20px;
    font-size: 25px;

    .dropdown {
        margin: .1em .5em;
    }
    input {
        padding: .3em .5em;
    }

    .settings {
        header {
            font-size: 1.3em;
        }
        margin-bottom: 20px;
    }

    .buttons {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        > .match-status {
            margin-top: 0.5em;
        }
        > .match-action-buttons *, .toggler-buttons * {
            margin-top: 0.5em;
        }

        >.match-status {
            align-self: stretch;
            display: flex;
            align-items: center;
            color: $button-background-color;
            background-color: $button-color;
            border: 1px solid $button-background-color;
            margin-right: 1em;

            .item {
                padding: 0 0.5em;
            }
            >.item ~ .item {
                border-left: 1px solid $button-background-color;
            }
        }
    }
}