
.deal-differently {

    .cards-for-player-selector {
        text-align: right;
        margin-right: 1em;

        &:not(:first-child) {
            margin-top: 1em;
        }
    }
}
