@import '../../styles/colors';

.auth-bar {
    input {
        color: $brand-black;
        background-color: $brand-white;
    }
    span {
        color: $brand-white;
    }

    padding: 6px 20px;
    min-height: 40px;
    background-color: $brand-green;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    > input {
        margin-left: 150px;
    }
}

.login-form, .create-user-form {
    display: inline-block;
    margin: 10px 20px;

    > span {
        margin-right: 10px;
    }

    > input {
        margin: 2px;
    }
}

.feedback {
    &.error {
        color: $brand-warn;
    }
    &.info {
        color: $brand-info;
    }
}