@import '../../../styles/colors';

.match-chat {
    &.hidden {
        display: none;
    }

    font-size: 80%;
    border: 1px dotted $brand-rich-cyan;
    margin: 1em 0;
    max-height: 11em;
    overflow-y: auto;
    overflow-x: hidden;
}
