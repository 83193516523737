
// Brand Colors
$brand-black: #202020;
$brand-white: #eee;
$brand-green: hsl(120, 33%, 27%);
$brand-yellow: hsl(50, 75%, 45%);
$brand-cyan: hsl(200, 100%, 75%);
$brand-turqoise: darken($brand-cyan, 20%);
$brand-blue: complement($brand-yellow);
$brand-light-red: hsl(10, 100%, 75%);
$brand-dark-gray: #363636;
$brand-light-gray: lighten($brand-dark-gray, 10%);
$brand-lighter-gray: lighten($brand-dark-gray, 20%);

$brand-rich-cyan: darken(desaturate($brand-cyan, 35%), 40%);

$brand-brighter-yellow: lighten(saturate($brand-yellow, 10%), 5%);
$brand-dark-yellow: darken($brand-yellow, 7%);

$brand-darkish-white: darken($brand-white, 5%);
$brand-dark-white: darken($brand-white, 20%);

// Element specific colors
$button-color: $brand-black;
$button-background-color: $brand-dark-white;

// Colors by meaning
$brand-info: $brand-cyan;
$brand-warn: $brand-light-red;
