@import 'links';
@import 'colors';
@import 'scrollbar';

body {
    margin: 0;
    background: $brand-black;
    color: $brand-white;
    font: 300 100.1% "Helvetica Neue", Helvetica, "Arial Unicode MS", Arial, sans-serif;
}
input {
    font-size: 1em;
    border: 0px hidden transparent;
    margin: 0 3px;
    outline: none;
}
input[type=button], input[type=submit] {
    color: $button-color;
    background-color: $button-background-color;
    cursor: pointer;
}
input[type=text], input[type=password] {
    padding-left: .2em;
}
