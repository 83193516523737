
.current-user-data {

    >.text {
        font-size: 0.9em;
        font-weight: lighter;
    }

    >.name {
        padding-left: 0.5em;
        font-weight: 900;
    }
}