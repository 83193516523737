
.deal-cards-modal {
    >.deal-mode-selector {
    }
    >.deal-form {
        margin: 2em 0;
    }
    >.deal-button {
    }
}
