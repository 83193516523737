// Source: https://css-tricks.com/custom-scrollbars-in-webkit/
::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    // border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
    border-radius: 10px;
}

// Gray
::-webkit-scrollbar-track {
    background-color: $brand-dark-gray;
}

::-webkit-scrollbar-thumb {
    background-color: $brand-light-gray;
}

// // Cyan
// ::-webkit-scrollbar-track {
//     background-color: $brand-cyan;
// }

// ::-webkit-scrollbar-thumb {
//     background-color: $brand-rich-cyan;
// }
