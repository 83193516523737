@import '../../../styles/colors';

.trick-winner-modal {

    >.description {
        >.highlight {
            color: $brand-cyan;
        }
    }

    input[type=button] {
        margin: 2em .5em;

        &.button-accept {
            background-color: $brand-green;
            color: $brand-white;
        }
        &.button-reject {
            background-color: $brand-black;
            color: $brand-white;
        }
    }
}