@import '../../../styles/colors';

.chat-input {
    background-color: $brand-dark-gray;
    overflow: hidden;

    >.message {
        margin: 0;
        padding:    0.5em;
        width: 75%;
        background-color: $brand-dark-gray;
        color: $brand-dark-yellow;
    }
    >.button {
        margin: 0;
        padding: 0.5em 2em;
        float: right;
        background-color: $brand-green;
        color: $brand-white;
    }
}