@import '../../../styles/colors';

$padding-vertical: 0.2em;

.chat-messages {
    >.message {
        padding: $padding-vertical 0.5em;
        border-bottom: 1px dotted $brand-dark-gray;
        line-height: 1.5em;

        >.user-name {
            color: $brand-green;
        }
        >.text {
            color: $brand-dark-yellow;
            margin-left: 0.6em;
            margin-right: 2em;
        }
        >.time {
            font-size: 70%;
            color: $brand-lighter-gray;
            float: right;
            margin: auto;
            margin-top: 2px;
            margin-right: 2.2em;
        }
        >.delete-button {
            color: $brand-light-red;
            background-color: transparent;
            float: right;
            padding: 0;
            margin: $padding-vertical 0;
            margin-left: -1em;
        }
    }
}