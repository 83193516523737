@import '../../styles/colors';

$background-color: $brand-black;
$background-color-open: $brand-green;
$border-thickness: 1px;
$border-thickness-open: 1px;
$border-color: #999;

.dropdown {
    display: inline-block;
    padding: 0.12em 0.25em;
    border: $border-thickness solid $border-color;
    background-color: $background-color;
    cursor: pointer;
    text-align: left;

    a:hover {
        color: $brand-turqoise !important;
    }

    &.open {
        border: $border-thickness-open solid $border-color;
        background-color: $background-color-open;
    }

    user-select: none;
    -webkit-user-select: none;

    > .dropdown-selected-option {
        display: inline-block;
        width: 100%;
    }

    position: relative;
    >.option-list {
        position: absolute;
        z-index: 10;
        top: 100%;
        left: -$border-thickness-open;

        border: $border-thickness-open solid $border-color;
        background-color: $background-color-open;

        display: flex;
        flex-direction: column;

        >.option-list-item {
            font-size: 0.8em;
            padding: 0.1em 0.4em;
            white-space: nowrap;
        }
        >.option-list-item.isSelected {
            color: $brand-brighter-yellow;
        }
    }
}