@import '../../styles/colors';

.new-match {
    padding: 2em 0;
    font-size: 3rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: space-around;

    .rules {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .dropdown {
            margin: .1em .5em;
        }
    }
    input {
        margin-top: 1em;
        color: $brand-black;
    }
    input[type=button] {
        padding: .3em .5em;
        background-color: $brand-green;
        color: $brand-white;
    }
}