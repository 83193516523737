@import '../../styles/colors';

.button-component {
    font-size: 1em;
    border: 0px hidden transparent;
    margin: 0 3px;
    outline: none;

    padding: .3em .5em;

    cursor: pointer;

    &.basic {
        background-color: $button-background-color;
        color: $button-color;
    }
    &.primary {
        background-color: $brand-green;
        color: $brand-white;
    }
    &.info {
        background-color: $brand-rich-cyan;
        color: $brand-white;
    }
    &.subtle {
        background-color: $brand-dark-gray;
        color: $brand-darkish-white;
    }
}
